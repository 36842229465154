import { Link } from "react-router-dom";

export default function Footer(props) {
    return <footer id="footer" className="footer position-relative light-background">

        <div className="container footer-top">
            <div className="row gy-4">
                <div className="col-lg-4 col-md-6 footer-about">
                    <Link href="index.html" className="logo d-flex align-items-center">
                        <span className="sitename">Bold Elevate</span>
                    </Link>
                    <div className="footer-contact pt-3">
                        <p>27, Central Road Apex Circle, Malviya Nagar Industrial Area, </p>
                        <p>Malviya Nagar, Jaipur, Rajasthan - 302017</p>
                        <p className="mt-3"><strong>Phone:</strong> <span>+91 7737667791</span></p>
                        <p><strong>Email:</strong> <span>boldelevatedigital@gmail.com</span></p>
                    </div>
                    <div className="social-links d-flex mt-4">
                        <a href="https://www.instagram.com/boldelevatedigital/"><i className="bi bi-instagram"></i></a>
                        <a href="https://www.threads.net/@boldelevatedigital"><i className="bi bi-threads"></i></a>
                        <a href="https://www.youtube.com/@BoldElevate"><i className="bi bi-youtube"></i></a>
                        <a href="https://x.com/BoldElevate"><i className="bi bi-twitter-x"></i></a>
                        <a href="https://github.com/boldelevate"><i className="bi bi-github"></i></a>
                        {/* <Link href=""><i className="bi bi-facebook"></i></Link> */}
                        {/* <Link href=""><i className="bi bi-linkedin"></i></Link> */}
                    </div>
                </div>

                <div className="col-lg-2 col-md-3 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/plans">Plans</Link></li>
                        <li><Link href="/terms-of-service">Terms of service</Link></li>
                        <li><Link href="/privacy-policy">Privacy policy</Link></li>
                    </ul>
                </div>

                <div className="col-lg-2 col-md-3 footer-links">
                    <h4>Our Services</h4>
                    <ul>
                        <li><Link href="#">Get Started</Link></li>
                    </ul>
                </div>

                <div className="col-lg-4 col-md-12 footer-newsletter">
                    <h4>Our Newsletter</h4>
                    <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
                    <form action="forms/newsletter.php" method="post" className="php-email-form">
                        <div className="newsletter-form">
                            <input type="email" name="email" />
                            <input type="submit" value="Subscribe" />
                        </div>
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your subscription request has been sent. Thank you!</div>
                    </form>
                </div>

            </div>
        </div>

        <div className="container copyright text-center mt-4">
            <p>© <span>Copyright</span> <strong className="px-1 sitename">Bold Elevate</strong><span>All Rights Reserved</span></p>
            <div className="credits">
                Designed by <Link href="https://bootstrapmade.com/">BootstrapMade</Link>
            </div>
        </div>

    </footer>
}