
/* web app pages */
import PlansPage from "./pages/PlansPage/PlansPage";
import HomePage from "./pages/HomePage/HomePage";

/* react-router-dom */
import { Navigate } from "react-router-dom";

const routes = [
    {
        path: "*",
        page: <Navigate to="/" />
    },
    {
        path: "/",
        page: <HomePage />
    },
    {
        path: "/home",
        page: <Navigate to="/" />
    },
    {
        path: "/admin",
        page: <HomePage />
    },
    {
        path: "/plans",
        page: <PlansPage />
    },
]

export default routes;