import WebPageCommonWrapper from "../../components/WebPageWrappers/WebPageCommonWrapper/WebPageCommonWrapper";
import BrandedProductandPackagingDesigns from "../../assets/img/plans/BrandedProductandPackagingDesigns.jpg";
import CustomerExperienceOptimization from "../../assets/img/plans/CustomerExperienceOptimization.jpg";
import DedicatedMarketingCampaigns from "../../assets/img/plans/DedicatedMarketingCampaigns.jpg";
import DigitalPresenceEnhancement from "../../assets/img/plans/DigitalPresenceEnhancement.jpg";
import BrandIdentityDevelopment from "../../assets/img/plans/BrandIdentityDevelopment.jpg";
import PersonalizedInfluencer from "../../assets/img/plans/PersonalizedInfluencer.jpg";
import StarConnectShoutouts from "../../assets/img/plans/StarConnectShoutouts.jpg";
import ReputationManagement from "../../assets/img/plans/ReputationManagement.jpg";

import { Tabs } from "../../values";
import PricingSection from "../../sections/PricingSection";

export default function PlansPage() {
    return (
        <WebPageCommonWrapper activeTab={Tabs.Plans}>
            <section id="more-features" className="more-features section features-details">
                <br />
                <br />
                <br />

                <div className="container section-title aos-init aos-animate" data-aos="fade-up">
                    <h2>The Plans to Success</h2>
                    <p>At Bold Elevate, we understand that every business has unique branding needs and budgets. That's why we offer a range of affordable, high-quality plans designed to help your brand achieve its full potential. Our Premium, Enterprise, and Ultimate plans provide comprehensive services that cater to various stages of business growth. Our plans are crafted to deliver exceptional value and results. Choose a plan that best fits your goals and let Bold Elevate craft your brand to success.</p>
                </div>

                <div className="container">
                    <div className="row justify-content-around gy-4">
                        <h3 className="bronze-heading">Premium Plan</h3>
                    </div>
                </div>

                <br />

                <div className="container">

                    <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">Brand Identity Development</p>
                            <p>Creating a strong and memorable brand identity is the cornerstone of our services. We work closely with you to understand your business’s essence, values, and goals.</p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-star flex-shrink-0'></i>
                                    <div>
                                        <h4>Logo Design</h4>
                                        <p>Crafting unique logos that symbolize your brand’s personality and mission.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-text flex-shrink-0'></i>
                                    <div>
                                        <h4>Typography</h4>
                                        <p>Choosing fonts that align with your brand’s voice and are visually appealing.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-palette flex-shrink-0'></i>
                                    <div>
                                        <h4>Color Schemes</h4>
                                        <p>Selecting color palettes that evoke the right emotions and represent your brand’s identity.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bx bx-spa flex-shrink-0"></i>
                                    <div>
                                        <h4>Visual Elements</h4>
                                        <p>Developing consistent visual elements, such as icons and graphics, that enhance your brand’s aesthetic.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <img src={BrandIdentityDevelopment} className="img-fluid" alt="Bold Elevate Service - Brand Identity Development" aria-label="Bold Elevate Service - Brand Identity Development" title="Bold Elevate Service - Brand Identity Development" />
                        </div>
                    </div>

                    {/* <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                            <img src={BrandIdentityDevelopment} className="img-fluid" alt="Bold Elevate Service - " />
                        </div>
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">Brand Identity Development</p>
                            <p>Creating a strong and memorable brand identity is the cornerstone of our services. We work closely with you to understand your business’s essence, values, and goals.</p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-star flex-shrink-0"></i>
                                    <div>
                                        <h4>Logo Design</h4>
                                        <p>Crafting unique logos that symbolize your brand’s personality and mission.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-fonts flex-shrink-0"></i>
                                    <div>
                                        <h4>Typography</h4>
                                        <p>Choosing fonts that align with your brand’s voice and are visually appealing.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-palette flex-shrink-0"></i>
                                    <div>
                                        <h4>Color Schemes</h4>
                                        <p>Selecting color palettes that evoke the right emotions and represent your brand’s identity.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-brightness-high flex-shrink-0"></i>
                                    <div>
                                        <h4>Visual Elements</h4>
                                        <p>Developing consistent visual elements, such as icons and graphics, that enhance your brand’s aesthetic.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> */}

                    <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                            <img src={DigitalPresenceEnhancement} className="img-fluid" alt="Bold Elevate Service - Digital Presence Enhancement" aria-label="Bold Elevate Service - Digital Presence Enhancement" title="Bold Elevate Service - Digital Presence Enhancement" />
                        </div>
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">Digital Presence Enhancement</p>
                            <p>In today’s digital world, a strong online presence is crucial. We create and optimize websites that not only look great but also offer an excellent user experience. </p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-globe flex-shrink-0'></i>
                                    <div>
                                        <h4>Website Design and Development</h4>
                                        <p>Building responsive, user-friendly websites that reflect your brand identity and engage visitors.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bx bxl-google flex-shrink-0"></i>
                                    <div>
                                        <h4>Google Business Listing</h4>
                                        <p>Creating and optimizing a Google Business Listing for maximum brand visibility.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-search flex-shrink-0'></i>
                                    <div>
                                        <h4>Search Engine Optimization (SEO)</h4>
                                        <p>Optimizing your website to rank higher in search engine results, driving organic traffic.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-badge-check flex-shrink-0'></i>
                                    <div>
                                        <h4>Digital Brand Presence</h4>
                                        <p>Listing your business on relevant Marketplaces, Blogs and Digital Economic Hubs.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">Personalized Influencer</p>
                            <p>This innovative solution leverages AI to create a digital influencer that embodies your brand's values and story, helping foster a deeper connection with your target audience and potential customers.</p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-magic flex-shrink-0"></i>
                                    <div>
                                        <h4>AI Influencer Design and Creation</h4>
                                        <p>Utilize our advanced AI technology to develop the influencer’s behavior, language, and interactions to reflect your brand’s values and story.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-images flex-shrink-0"></i>
                                    <div>
                                        <h4>Content Creation and Distribution</h4>
                                        <p>Developing engaging content and distributing across social media platforms, to maximize reach and engagement.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-instagram flex-shrink-0"></i>
                                    <div>
                                        <h4>Social Media Maketing</h4>
                                        <p>The Personalized Influencer will actively promote your brand on social media channels developing a strong brand presence</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className="bi bi-bar-chart flex-shrink-0"></i>
                                    <div>
                                        <h4>Performance Tracking and Optimization</h4>
                                        <p>Analyzing the performance to continuously optimize the influencer’s content for better engagement and results.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <img src={PersonalizedInfluencer} className="img-fluid" alt="Bold Elevate Service - Personalized Influencer" aria-label="Bold Elevate Service - Personalized Influencer" title="Bold Elevate Service - Personalized Influencer" />
                        </div>
                    </div>

                    <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                            <img src={StarConnectShoutouts} className="img-fluid" alt="Bold Elevate Service - StarConnect Shoutouts" aria-label="Bold Elevate Service - StarConnect Shoutouts" title="Bold Elevate Service - StarConnect Shoutouts" />
                        </div>
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">StarConnect Shoutouts</p>
                            <p>StarConnect Shoutouts leverage our extensive network of influencers with diverse and broad audiences to promote your brand effectively.  </p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-award flex-shrink-0'></i>
                                    <div>
                                        <h4>Enhance Credibility</h4>
                                        <p>Endorsements from trusted influencers enhance your brand’s credibility and foster trust among their followers.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-line-chart flex-shrink-0'></i>
                                    <div>
                                        <h4>Extended Reach</h4>
                                        <p>
                                            Tap into StarConnect’s vast network of influencers to expand your brand’s visibility and reach new audiences.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-current-location flex-shrink-0'></i>
                                    <div>
                                        <h4>Targeted Exposure</h4>
                                        <p>
                                            Deliver tailored messages about your brand to specific demographics or interest groups within the network.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-show flex-shrink-0'></i>
                                    <div>
                                        <h4>Increased Brand Visibility</h4>
                                        <p>Gain exposure among diverse audience segments, enhancing your brand’s visibility and recognition.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <br />
                <br />

                <div className="container">
                    <div className="row justify-content-around gy-4">
                        <h3 className="gold-heading">Enterprise Plan</h3>
                    </div>
                </div>

                <br />

                <div className="container">

                    <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">Customer Experience Optimization</p>
                            <p>A great brand is built on exceptional customer experiences. We help you enhance every touchpoint with your customers, ensuring their interactions with your brand are positive and memorable.</p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-happy-alt flex-shrink-0'></i>
                                    <div>
                                        <h4>Personalized Experiences</h4>
                                        <p>Creating personalized experiences that make customers feel valued and appreciated.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-heart flex-shrink-0'></i>
                                    <div>
                                        <h4>Loyalty Programs</h4>
                                        <p>Developing loyalty programs that reward repeat customers and encourage long-term relationships.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-support  flex-shrink-0'></i>
                                    <div>
                                        <h4>Customer Service Optimization</h4>
                                        <p>
                                            Improving your customer service processes to ensure timely and helpful responses.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-analyse flex-shrink-0'></i>
                                    <div>
                                        <h4>Feedback Collection and Analysis</h4>
                                        <p>Gathering and analyzing customer feedback to identify areas for improvement.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <img src={CustomerExperienceOptimization} className="img-fluid" alt="Bold Elevate Service - Customer Experience Optimization" aria-label="Bold Elevate Service - Customer Experience Optimization" title="Bold Elevate Service - Customer Experience Optimization" />
                        </div>
                    </div>

                    <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                            <img src={BrandedProductandPackagingDesigns} className="img-fluid" alt="Bold Elevate Service - Branded Product and Packaging Designs" aria-label="Bold Elevate Service - Branded Product and Packaging Designs" title="Bold Elevate Service - Branded Product and Packaging Designs" />
                        </div>
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">Branded Product and Packaging Designs</p>
                            <p>Effective product and packaging designs capture attention, communicate your brand’s story, and create a memorable experience for customers. </p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-cube-alt flex-shrink-0'></i>
                                    <div>
                                        <h4>Design Scheme Development</h4>
                                        <p>Creating cohesive and visually appealing design schemes that make your products stand out.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-palette flex-shrink-0'></i>
                                    <div>
                                        <h4>Color Scheme Optimization</h4>
                                        <p>Selecting the right colors that evoke the desired emotions and align with your brand identity.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-spa flex-shrink-0'></i>
                                    <div>
                                        <h4>Brand Logo Integration</h4>
                                        <p>Ensuring your brand logo is prominently and attractively displayed on all products and packaging.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-purchase-tag-alt flex-shrink-0'></i>
                                    <div>
                                        <h4>Labeling and Branding</h4>
                                        <p>Design labels and branding elements that clearly convey product information and brand messaging.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />

                <div className="container">
                    <div className="row justify-content-around gy-4">
                        <h3 className="platinum-heading">Ultimate Plan</h3>
                    </div>
                </div>

                <br />

                <div className="container">

                    <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">Dedicated Marketing Campaigns</p>
                            <p>Creating a strong and memorable brand identity is the cornerstone of our services. We work closely with you to understand your business’s essence, values, and goals.</p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-trending-up flex-shrink-0'></i>
                                    <div>
                                        <h4>Campaign Strategy Development</h4>
                                        <p>We create a comprehensive strategy tailored to your business objectives and target audience.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-images flex-shrink-0'></i>
                                    <div>
                                        <h4>Creative Content Production</h4>
                                        <p>We develop engaging and visually appealing content for your campaign, including ads, videos, and social media posts.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-layer flex-shrink-0'></i>
                                    <div>
                                        <h4>Multi-Channel Execution</h4>
                                        <p>We deploy your campaign across multiple channels, such as social media, email, search engines, and display networks.</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-pie-chart-alt-2 flex-shrink-0'></i>
                                    <div>
                                        <h4>Performance Tracking and Optimization</h4>
                                        <p>We monitor campaign performance in real-time and make data-driven adjustments to optimize results.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <img src={DedicatedMarketingCampaigns} className="img-fluid" alt="Bold Elevate Service - Dedicated Marketing Campaigns" aria-label="Bold Elevate Service - Dedicated Marketing Campaigns" title="Bold Elevate Service - Dedicated Marketing Campaigns" />
                        </div>
                    </div>

                    <div className="row gy-4 justify-content-between features-item">
                        <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                            <img src={ReputationManagement} className="img-fluid" alt="Bold Elevate Service - Reputation Management" aria-label="Bold Elevate Service - Reputation Management" title="Bold Elevate Service - Reputation Management" />
                        </div>
                        <div className="col-lg-5 d-flex flex-column justify-content-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <p className="who-we-are">Reputation Management</p>
                            <p>
                                Your brand’s reputation is one of its most valuable assets. We monitor and manage your online reputation to ensure your brand maintains a positive image.
                            </p>

                            <div className="row">

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-star flex-shrink-0'></i>
                                    <div>
                                        <h4>Review Monitoring</h4>
                                        <p>
                                            Keeping track of online reviews and addressing negative feedback promptly.
                                        </p>
                                    </div>
                                </div>


                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-happy-beaming flex-shrink-0'></i>
                                    <div>
                                        <h4>Positive Public Relations (PR)</h4>
                                        <p>
                                            Promoting positive stories and achievements to enhance your brand’s image.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-first-aid flex-shrink-0'></i>
                                    <div>
                                        <h4>Crisis Management</h4>
                                        <p>
                                            Developing strategies to manage and mitigate any crises that may arise.
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-6 icon-box d-flex">
                                    <i className='bx bx-user-voice flex-shrink-0'></i>
                                    <div>
                                        <h4>Social Listening</h4>
                                        <p>
                                            Monitoring social media conversations to understand public perception and address any issues proactively.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <PricingSection />
        </WebPageCommonWrapper>
    )
}