import RoutingService from './services/RoutingService/RoutingService';
import ScrollToTop from './services/ScrollToTop/ScrollToTop';
import "./assets/css/main.css"
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import React from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <RoutingService />
  </BrowserRouter>
);