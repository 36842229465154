import WebPageCommonWrapper from "../../components/WebPageWrappers/WebPageCommonWrapper/WebPageCommonWrapper";
import BoostVisibilityAndRecognition from "../../assets/img/how-we-help/BoostVisibilityAndRecognition.jpg";
import EnhanceCustomerConnection from "../../assets/img/how-we-help/EnhanceCustomerConnection.jpg";
import ImproveMarketPositioning from "../../assets/img/how-we-help/ImproveMarketPositioning.jpg";
import SupportLongTermGrowth from "../../assets/img/how-we-help/SupportLongTermGrowth.jpg";
import StrongBrandIdentity from "../../assets/img/how-we-help/StrongBrandIdentity.jpg";
import PricingSection from "../../sections/PricingSection";
import HeroImage from "../../assets/img/hero.png";
import { Link } from "react-router-dom";
import { Tabs } from "../../values";

function HomePage() {
    return (
        <WebPageCommonWrapper activeTab={Tabs.Home}>

            <section id="hero" className="d-flex align-items-center">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 pt-2 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1>Business to Brand</h1>
                            {/* <ul>
                                <li><i className="ri-check-line"></i> Dolorem ratione dolorum</li>
                                <li><i className="ri-check-line"></i> Quo nihil natus ea non pariatur optio occaecati</li>
                                <li><i className="ri-check-line"></i> Duis aute irure dolor in reprehenderit in</li>
                            </ul> */}
                            <p>
                                <br />
                                {/* We create strong, memorable brand identities that set businesses apart. Our comprehensive approach enhances your digital presence, engages your audience, and builds lasting customer trust. */}
                                At Bold Elevate, we specialize in transforming businesses into unforgettable brands. Our mission is to elevate your business by crafting a distinct and powerful brand image that resonates with your target audience and drives growth.
                            </p>
                            <div className="mt-3">
                                <Link href="#about" className="btn-get-started scrollto">Get Started</Link>
                                <Link to="/plans" className="btn-get-quote">Explore Plans</Link>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img">
                            <img src={HeroImage} className="img-fluid" alt="Bold Elevate - people transforming a business into brand" aria-label="Bold Elevate - people transforming a business into brand" />
                        </div>
                    </div>
                </div>

            </section>

            <main className="main">

                <section id="features-details" className="features-details section">

                    <div className="container section-title aos-init aos-animate" data-aos="fade-up">
                        <h2>We Help Your Business Grow</h2>
                        <p>We are dedicated to transforming your business into a powerful force in the market. Our comprehensive services ensure that your brand stands out, builds trust, and drives sustainable growth in a competitive market.</p>
                    </div>

                    <div className="container">

                        <div className="row gy-4 justify-content-between features-item">
                            <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <img src={StrongBrandIdentity} className="img-fluid" alt="Bold Elevate helps you create a strong brand identity" aria-label="Bold Elevate helps you create a strong brand identity" title="Bold Elevate helps you create a strong brand identity" />
                            </div>
                            <div className="col-lg-5 d-flex align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <div className="content">
                                    <h3>Build a Strong Brand Identity</h3>
                                    <p>
                                        We help you establish a clear and compelling brand identity that sets you apart from the competition. By defining your unique voice and visual style, we ensure your brand is instantly recognizable and memorable.
                                    </p>
                                    <Link to="/" className="btn more-btn">Learn More</Link>
                                </div>
                            </div>
                        </div>

                        <div className="row gy-4 justify-content-between features-item">
                            <div className="col-lg-5 d-flex align-items-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="content">
                                    <h3> Enhance Customer Connection</h3>
                                    <p>
                                        A strong brand goes beyond a logo or tagline. We create an <span className="highlight-text">emotional connection</span> between your business and your customers, fostering loyalty and trust. This connection encourages repeat business and word-of-mouth referrals.
                                    </p>
                                    {/* <ul>
                                        <li><i className="bi bi-easel flex-shrink-0"></i> Et corporis ea eveniet ducimus.</li>
                                        <li><i className="bi bi-patch-check flex-shrink-0"></i> Exercitationem dolorem sapiente.</li>
                                        <li><i className="bi bi-brightness-high flex-shrink-0"></i> Veniam quia modi magnam.</li>
                                    </ul> */}
                                    <p></p>
                                    <Link to="/" className="btn more-btn">Learn More</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <img src={EnhanceCustomerConnection} className="img-fluid" alt="Bold Elevate helps you enhance customer connection" aria-label="Bold Elevate helps you enhance customer connection" title="Bold Elevate helps you enhance customer connection" />
                            </div>
                        </div>

                        <div className="row gy-4 justify-content-between features-item">
                            <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <img src={BoostVisibilityAndRecognition} className="img-fluid" alt="Bold Elevate helps you boost the visibility and recognition of your brand" title="Bold Elevate helps you boost the visibility and recognition of your brand" aria-label="Bold Elevate helps you boost the visibility and recognition of your brand" />
                            </div>
                            <div className="col-lg-5 d-flex align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <div className="content">
                                    <h3>Boost Visibility and Recognition</h3>
                                    <p>
                                        We increase your brand’s visibility through strategic branding efforts that make you stand out in a crowded marketplace. A strong brand identity enhances recognition and helps your business stay top-of-mind with potential customers.
                                    </p>
                                    <Link to="/" className="btn more-btn">Learn More</Link>
                                </div>
                            </div>
                        </div>

                        <div className="row gy-4 justify-content-between features-item">
                            <div className="col-lg-5 d-flex align-items-center order-2 order-lg-1 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <div className="content">
                                    <h3>Improve Market Positioning</h3>
                                    <p>
                                        Our branding expertise helps position your business as a leader in your industry. With a well-crafted brand image, you can confidently communicate your value proposition, attracting the right audience and increasing market share.
                                    </p>
                                    <p></p>
                                    <Link to="/" className="btn more-btn">Learn More</Link>
                                </div>
                            </div> u
                            <div className="col-lg-6 order-1 order-lg-2 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <img src={ImproveMarketPositioning} className="img-fluid" alt="Bold Elevate helps you improver the market positioning of your brand" aria-label="Bold Elevate helps you improver the market positioning of your brand" title="Bold Elevate helps you improver the market positioning of your brand" />
                            </div>
                        </div>

                        <div className="row gy-4 justify-content-between features-item">
                            <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                <img src={SupportLongTermGrowth} className="img-fluid" alt="Bold Elevate helps you sustain long term growth for your brand" title="Bold Elevate helps you sustain long term growth for your brand" aria-label="Bold Elevate helps you sustain long term growth for your brand" />
                            </div>
                            <div className="col-lg-5 d-flex align-items-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <div className="content">
                                    <h3>Support Long-Term Growth</h3>
                                    <p>
                                        A well-defined brand lays the foundation for sustainable growth. By establishing a strong brand presence, we help your business attract new opportunities, expand into new markets, and achieve long-term success.
                                    </p>
                                    <Link to="/" className="btn more-btn">Learn More</Link>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>


                <section id="clients" className="clients section">

                    <div className="container section-title aos-init aos-animate" data-aos="fade-up">
                        <h2>Clients</h2>
                        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
                    </div>

                    <div className="container aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">

                        <div className="row g-0 clients-wrap">

                            <div className="col-xl-3 col-md-4 client-logo">
                                <img src="assets/img/clients/client-1.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-xl-3 col-md-4 client-logo">
                                <img src="assets/img/clients/client-2.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-xl-3 col-md-4 client-logo">
                                <img src="assets/img/clients/client-3.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-xl-3 col-md-4 client-logo">
                                <img src="assets/img/clients/client-4.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-xl-3 col-md-4 client-logo">
                                <img src="assets/img/clients/client-5.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-xl-3 col-md-4 client-logo">
                                <img src="assets/img/clients/client-6.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-xl-3 col-md-4 client-logo">
                                <img src="assets/img/clients/client-7.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-xl-3 col-md-4 client-logo">
                                <img src="assets/img/clients/client-8.png" className="img-fluid" alt="" />
                            </div>

                        </div>

                    </div>

                </section>

                <PricingSection />

                <section id="faq" className="faq section">

                    <div className="container section-title aos-init aos-animate" data-aos="fade-up">
                        <h2>Frequently Asked Questions</h2>
                    </div>

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-lg-10 aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">

                                <div className="faq-container">

                                    <div className="faq-item">
                                        <h3> What services does Bold Elevate offer?</h3>
                                        <div className="faq-content">
                                            <p>
                                                We offer brand identity development, consistent brand messaging, digital presence enhancement, content creation, customer experience optimization, reputation management, market analysis, and partnership collaboration.
                                            </p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>How can Bold Elevate help my business?</h3>
                                        <div className="faq-content">
                                            <p>
                                                We build a strong, memorable brand image that increases recognition, fosters customer loyalty, enhances credibility, improves engagement, and provides a competitive advantage.
                                            </p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>Do you offer customized solutions for each client?</h3>
                                        <div className="faq-content">
                                            <p>
                                                Yes, we tailor our services to meet the unique needs and goals of each client, ensuring a personalized approach to building your brand.
                                            </p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>How do you measure the success of your branding efforts?</h3>
                                        <div className="faq-content">
                                            <p>
                                                We use analytics and key performance indicators (KPIs) to track the effectiveness of our strategies and make data-driven adjustments to ensure continuous improvement.
                                            </p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                    <div className="faq-item">
                                        <h3>How do I get started with Bold Elevate?</h3>
                                        <div className="faq-content">
                                            <p>Simply contact us through our website or give us a call. We’ll set up a consultation to discuss your needs and how we can help build your brand.</p>
                                        </div>
                                        <i className="faq-toggle bi bi-chevron-right"></i>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </section>
                <section id="testimonials" className="testimonials section light-background">

                    <div className="container section-title aos-init aos-animate" data-aos="fade-up">
                        <h2>Testimonials</h2>
                        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
                    </div>

                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <div className="swiper init-swiper">
                            <div className="swiper-wrapper">

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" />
                                            <h3>David</h3>
                                            <h4>Health Clinic Manager</h4>
                                            <div className="stars">
                                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                <span>
                                                    Bold Elevate transformed our clinic's brand, making it more approachable and trustworthy. We've seen a noticeable increase in patient inquiries and positive feedback. Fantastic experience!
                                                </span>
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" />
                                            <h3>Rachel</h3>
                                            <h4>E-commerce Store Owner</h4>
                                            <div className="stars">
                                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                <span>
                                                    Bold Elevate helped us create a cohesive brand image that resonates with our customers. The boost in online traffic and sales is remarkable. Couldn't be happier!
                                                </span>
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="" />
                                            <h3>Emily</h3>
                                            <h4>Fashion Designer</h4>
                                            <div className="stars">
                                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                <span>
                                                    Bold Elevate elevated our brand to new heights. The visibility and recognition we've gained are phenomenal. Their team truly understands the essence of branding.
                                                </span>
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt="" />
                                            <h3>Alex</h3>
                                            <h4>Real Estate Agent</h4>
                                            <div className="stars">
                                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                <span>
                                                    Working with Bold Elevate was a game-changer. Our new brand image has significantly enhanced our market presence and client trust. Highly effective and professional.
                                                </span>
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="swiper-slide">
                                    <div className="testimonial-wrap">
                                        <div className="testimonial-item">
                                            <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt="" />
                                            <h3>Tom</h3>
                                            <h4>Financial Services Provider</h4>
                                            <div className="stars">
                                                <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                            </div>
                                            <p>
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                <span>
                                                    Bold Elevate's branding strategies have immensely improved our online reputation and client engagement. We've gained a lot more trust from our target audience. Outstanding work!
                                                </span>
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="swiper-pagination"></div>
                        </div>

                    </div>

                </section>

                <section id="contact" className="contact section">

                    <div className="container section-title" data-aos="fade-up">
                        <h2>Contact</h2>
                        <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
                    </div>

                    <div className="container" data-aos="fade-up" data-aos-delay="100">

                        <div className="row gy-4">

                            <div className="col-lg-6">
                                <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="200">
                                    <i className="bi bi-geo-alt"></i>
                                    <h3>Address</h3>
                                    <p>27, Central Road Apex Circle, Malviya Nagar Industrial Area, </p>
                                    <p>Malviya Nagar, Jaipur, Rajasthan - 302017</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="300">
                                    <i className="bi bi-telephone"></i>
                                    <h3>Call Us</h3>
                                    <p>+91 7737667791</p>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="400">
                                    <i className="bi bi-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>boldelevatedigital@gmail.com</p>
                                </div>
                            </div>

                        </div>

                        <div className="row gy-4 mt-1">
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d48389.78314118045!2d-74.006138!3d40.710059!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a22a3bda30d%3A0xb89d1fe6bc499443!2sDowntown%20Conference%20Center!5e0!3m2!1sen!2sus!4v1676961268712!5m2!1sen!2sus" frameBorder="0" title="map-iframe" style={{ border: 0, width: "100%", height: "405px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <div className="col-lg-6">
                                <form action="forms/contact.php" method="post" className="php-email-form" data-aos="fade-up" data-aos-delay="400" onSubmit={(e) => { e.preventDefault() }}>
                                    <div className="row gy-4">

                                        <div className="col-md-6">
                                            <input type="text" name="name" className="form-control" placeholder="Your Name" required="" />
                                        </div>

                                        <div className="col-md-6 ">
                                            <input type="email" className="form-control" name="email" placeholder="Your Email" required="" />
                                        </div>

                                        <div className="col-md-12">
                                            <input type="text" className="form-control" name="subject" placeholder="Subject" required="" />
                                        </div>

                                        <div className="col-md-12">
                                            <textarea className="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                                        </div>

                                        <div className="col-md-12 text-center">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>

                                            <button type="submit">Send Message</button>
                                        </div>

                                    </div>
                                </form>
                            </div>

                        </div>

                    </div>

                </section>

            </main>

        </WebPageCommonWrapper>
    );
}

export default HomePage;
