import { Currencies, currentCurrency } from "../../values"

const getSuitablePrice = (priceINR) => {

    if (currentCurrency === Currencies.INR.Symbol) {
        return ((Math.floor(priceINR / 500) * 500) - 1).toLocaleString();
    }

}

export { getSuitablePrice }