import { Link } from "react-router-dom";
import { Tabs } from "../../../values";

export default function Header(props) {
    return <header id="header" className="header d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl position-relative d-flex align-items-center">

            <Link to="/" className="logo d-flex align-items-center me-auto">
                <img src="assets/img/icon/icon.svg" alt="" className="logo-image" />
                <h1 className="sitename">Bold Elevate</h1>
            </Link>

            <nav id="navmenu" className="navmenu">
                <ul>
                    <li><Link to="/" className={(props.activeTab === Tabs.Home) ? "active" : ""}>Home</Link></li>
                    <li><Link to="/plans" className={(props.activeTab === Tabs.Plans) ? "active" : ""}>Plans</Link></li>
                    <li className="dropdown" style={{ display: "none" }}>
                        {/* <Link href="#"><span>More</span> <i className="bi bi-chevron-down toggle-dropdown"></i></Link> */}
                        <ul>
                            {/* <li><Link href="#">Dropdown 1</Link></li> */}
                            {/* <li className="dropdown"><Link href="#"><span>Deep Dropdown</span> <i className="bi bi-chevron-down toggle-dropdown"></i></Link>
                                <ul>
                                    <li><Link href="#">Deep Dropdown 1</Link></li>
                                    <li><Link href="#">Deep Dropdown 2</Link></li>
                                    <li><Link href="#">Deep Dropdown 3</Link></li>
                                    <li><Link href="#">Deep Dropdown 4</Link></li>
                                    <li><Link href="#">Deep Dropdown 5</Link></li>
                                </ul>
                            </li> */}
                            {/* <li><Link href="#">Dropdown 2</Link></li>
                            <li><Link href="#">Dropdown 3</Link></li>
                            <li><Link href="#">Dropdown 4</Link></li> */}
                        </ul>
                    </li>
                </ul>
                <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
            </nav>

            <Link className="btn-getstarted" href="index.html#about">Get Started</Link>

        </div>
    </header>
}