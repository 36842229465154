const Tabs = Object.freeze({
    Home: 0,
    Plans: 1,
})

const Currencies = Object.freeze({
    USD: Object.freeze({
        Symbol: "$",
        Name: "United States Dollar",
        toRupee: 85
    }),
    INR: Object.freeze({
        Symbol: "₹",
        Name: "Indian Rupee",
        toRupee: 1
    })
})

const PricingPlans = Object.freeze({
    Bronze: Object.freeze({
        price: 3000,
    }),
    Gold: Object.freeze({
        price: 5000,
    }),
    Platinum: Object.freeze({
        price: 8000,
    }),
})

const currentCurrency = Currencies.INR.Symbol;

export { Tabs, Currencies, PricingPlans, currentCurrency }