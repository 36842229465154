import { getSuitablePrice } from "../services/PricingService/PricingService";
import { PricingPlans, currentCurrency } from "../values";
import { Link } from "react-router-dom";

export default function PricingSection() {
    return <section id="pricing" className="pricing section">

        <div className="container section-title aos-init aos-animate" data-aos="fade-up">
            <h2>Pricing</h2>
            <p>Necessitatibus eius consequatur ex aliquid fuga eum quidem sint consectetur velit</p>
        </div>

        <div className="container">
            <div className="row gy-4">

                <div className="col-lg-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                    <div className="pricing-item">
                        <h3 className="bronze-heading">Premium Plan</h3>
                        <h4><sup>{currentCurrency}</sup>{getSuitablePrice(PricingPlans.Bronze.price)}<span> / month</span></h4>
                        {/* <p>Suitable for Micro Enterprises</p> */}
                        <ul>
                            {/* <li><i className="bi bi-check"></i> <span>Suitable for Small Business</span></li> */}
                            <li><i className="bi bi-check"></i> <span>Brand Identity Development</span></li>
                            <li><i className="bi bi-check"></i> <span>Digital Presence Enhancement</span></li>
                            <li><i className="bi bi-check"></i> <span>Personalized Influencer</span></li>
                            <li><i className="bi bi-check"></i> <span>2 StarConnect Shoutouts per month</span></li>
                        </ul>
                        <Link to="/" className="buy-btn">Buy Now</Link>
                    </div>
                </div>

                <div className="col-lg-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="200">
                    <div className="pricing-item featured">
                        <h3 className="gold-heading">Enterprise Plan</h3>
                        <h4><sup>{currentCurrency}</sup>{getSuitablePrice(PricingPlans.Gold.price)}<span> / month</span></h4>
                        <ul>
                            <li><i className="bi bi-check"></i> <span>Everything from Bronze</span></li>
                            {/* <li><i className="bi bi-check"></i> <span>Digital Presence Enhancement</span></li>
                            <li><i className="bi bi-check"></i> <span>Personalized Influencer</span></li> */}
                            <li><i className="bi bi-check"></i> <span>4 StarConnect Shoutouts per month</span></li>
                            <li><i className="bi bi-check"></i> <span>Customer Experience Optimization</span></li>
                            <li><i className="bi bi-check"></i> <span>Branded Product and Packaging Designs</span></li>
                            {/* <li className="na"><span>Massa ultricies mi quis hendrerit</span></li>
                            <li className="na"><span>Massa ultricies mi quis hendrerit</span></li> */}
                        </ul>
                        <Link to="/" className="buy-btn">Buy Now</Link>
                    </div>
                </div>

                <div className="col-lg-4 aos-init aos-animate" data-aos="zoom-in" data-aos-delay="300">
                    <div className="pricing-item">
                        <h3 className="platinum-heading">Ultimate Plan</h3>
                        <h4><sup>{currentCurrency}</sup>{getSuitablePrice(PricingPlans.Platinum.price)}<span> / month</span></h4>
                        <ul>
                            <li><i className="bi bi-check"></i> <span>Everything from Gold</span></li>
                            {/* <li><i className="bi bi-check"></i> <span>Brand Identity Development</span></li>
                            <li><i className="bi bi-check"></i> <span>Digital Presence Enhancement</span></li>
                            <li><i className="bi bi-check"></i> <span>Personalized Influencer</span></li> */}
                            <li><i className="bi bi-check"></i> <span>7 StarConnect Shoutouts per month</span></li>
                            {/* <li><i className="bi bi-check"></i> <span>Customer Experience Optimization</span></li>
                            <li><i className="bi bi-check"></i> <span>Branded Content Creation and Marketing</span></li> */}
                            <li><i className="bi bi-check"></i> <span>Dedicated Marketing Campaigns</span></li>
                            <li><i className="bi bi-check"></i> <span>Reputation Management</span></li>
                        </ul>
                        <Link to="/" className="buy-btn">Buy Now</Link>
                    </div>
                </div>

            </div>
        </div>

        <div className="container pricing-duarations">
            {/* <div className="pricing-duaration active">Yearly</div>
            <div className="pricing-duaration">Monthly</div> */}
            <br />
            You are viewing Prices for Yearly and Half-Yearly Subscriptions, <span className="pseudo-link">See Monthly Subscription Prices</span>
        </div>

    </section >
}
